<template>
    <button :class="className" :type="typeName" :disabled="disableValue">
        <span v-if="loading"  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        {{ loading ? 'Loading...' : buttonName}}
    </button>
</template>
<script>
    export default {
    props: {
        className: String,
        typeName: String,
        loading:Boolean,
        buttonName:String,
        disableValue:Boolean,
    }
    }
</script>